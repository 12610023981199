<script lang="ts">
	import { fade } from 'svelte/transition'
	import { page } from '$app/stores'
	import { ActionPanel } from '$lib/components'
	import { storage_settings } from '$lib/stores'
	import { accept_all, deny_all, update_consent } from '.'
	import { onMount } from 'svelte'

	export let show_settings: boolean
	let dialog: HTMLDialogElement

	type Policy = [string, boolean]
	let policies: Policy[]

	$: ({ consent_groups } = $page?.data?.banner || {})
	$: policies =
		$storage_settings && 'policies' in $storage_settings
			? $storage_settings.policies
			: [['necessary_storage', true] as Policy]

	$: if (dialog) {
		if (
			$storage_settings &&
			'is_banner_visible' in $storage_settings &&
			$storage_settings.is_banner_visible
		) {
			dialog.showModal()
			document.body.style.overflow = 'hidden'
			document.body.style.position = 'fixed'
			document.body.style.width = '100%'
		} else {
			dialog.close()
			document.body.style.overflow = ''
			document.body.style.position = ''
			document.body.style.width = ''
		}
	}

	onMount(() => {
		return () => {
			document.body.style.overflow = ''
			document.body.style.position = ''
			document.body.style.width = ''
		}
	})
</script>

<dialog
	bind:this={dialog}
	class="fixed inset-0 z-30 h-[100dvh] overflow-hidden bg-transparent p-0"
	aria-labelledby="modal-title"
	aria-modal="true"
>
	<div transition:fade|global={{ duration: 150 }} class="grid h-full w-full place-items-center">
		<button
			type="button"
			class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
			on:click={() => {
				show_settings = false
			}}
			on:keydown={(e) => e.key === 'Escape' && (show_settings = false)}
		/>

		<div
			class="relative h-[90vh] w-[calc(100vw-2.5rem)] transform overflow-hidden rounded bg-white text-left shadow-xl transition-all sm:mx-auto sm:h-[80vh] sm:w-[640px] lg:w-[768px]"
		>
			<div class="flex h-full flex-col px-4 pb-4 pt-5 lg:p-6">
				<header class="flex items-center">
					<div class="flex h-12 w-12 flex-shrink-0 items-center justify-items-start text-3xl">
						🍪
					</div>
					<h2 id="modal-title" class="block w-full text-lg font-medium leading-6 text-gray-900">
						Cookie-Einstellungen
					</h2>
					<div class="flex items-center gap-4">
						<div class="hidden sm:flex">
							<a href="/datenschutz" class="text-blue-900 hover:underline">Datenschutzhinweise</a>
						</div>
						<div class="hidden sm:flex">
							<a href="/impressum" class="block text-blue-900 hover:underline">Impressum</a>
						</div>
						<button
							on:click={() => {
								show_settings = false
							}}
							class="p-2 text-blue-900">&larr;</button
						>
					</div>
				</header>

				<main class="mt-3 flex-1 overflow-y-auto sm:mt-5">
					<div>
						{#each consent_groups as { name, label, description, is_not_clickable }, i}
							<ActionPanel
								on:click={() => {
									if (!is_not_clickable) {
										const policy = policies.find((policy) => policy[0] === name)
										if (policy && $storage_settings && 'policies' in $storage_settings) {
											policy[1] = !policy[1]
											$storage_settings.policies = policies
											$storage_settings = $storage_settings
										}
									}
								}}
								{is_not_clickable}
								is_accepted={policies.find((policy) => policy[0] === name)?.[1] ?? false}
							>
								<h3 slot="title">
									{label}
								</h3>
								<svelte:fragment slot="description">{@html description}</svelte:fragment>
							</ActionPanel>
						{/each}
					</div>
				</main>

				<footer
					class="mt-4 grid flex-shrink-0 grid-flow-dense grid-cols-1 items-center gap-2 md:grid-cols-3 md:gap-3"
				>
					<button
						on:click={() => {
							accept_all(policies)
							show_settings = false
							location.reload() // Reload page to trigger GTM
						}}
						type="button"
						class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-8 py-4 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:col-start-3"
					>
						Alle akzeptieren
					</button>
					<button
						on:click={() => {
							update_consent(policies)
							show_settings = false
							location.reload() // Reload page to trigger GTM
						}}
						type="button"
						class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-200 bg-white px-8 py-4 text-base font-medium text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:col-start-2 sm:mt-0"
					>
						Auswahl speichern
					</button>
					<button
						on:click={() => {
							deny_all(policies)
							show_settings = false
							location.reload() // Reload page to trigger GTM
						}}
						type="button"
						class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-200 bg-white px-8 py-4 text-base font-medium text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0"
					>
						Alle ablehnen
					</button>
				</footer>

				<div class="mb-4 mt-6 flex flex-col place-items-center gap-4 sm:hidden">
					<div>
						<a href="/datenschutz" class="text-blue-900 hover:underline">Datenschutzhinweise</a>
					</div>
					<div>
						<a href="/impressum" class="block text-blue-900 hover:underline">Impressum</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</dialog>

<style lang="postcss">
	h2 {
		@apply block w-full text-lg font-medium leading-6 text-gray-900;
	}
</style>
